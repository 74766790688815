<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Distribution vehicle">
                <b-row>
                    <b-col lg="4">
                        <BasicOverview v-if="!editEnabled" :data="distributionVehicle" :definitions="overviewDefinitions"/>
                        <div v-else>

                            <b-form-group>
                                <label>Name</label>
                                <b-form-input v-model="editObject.name"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Type</label>
                                <b-form-input v-model="editObject.type"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Registration</label>
                                <b-form-input v-model="editObject.registration"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Warehouses</label>
                                <v-select
                                    v-model="editObject.assigned_warehouses"
                                    :reduce="type => type.id"
                                    label="name"
                                    multiple
                                    :options="warehouses"
                                />
                            </b-form-group>
                        </div>
                        <hr/>

                        <b-button v-if="!editEnabled && $hasPermission($permissions.EditDistributionVehicles)" variant="warning" @click="openEdit" class="mr-1">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="saveDistributionVehicle" class="mr-1">
                            <feather-icon icon="SaveIcon" class="mr-50"/>
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="closeEdit">
                            <feather-icon icon="XIcon" class="mr-50"/>
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled && $hasPermission($permissions.RemoveDistributionVehicle)" variant="danger" @click="removeDistributionVehicle" class="float-right mr-1">
                            <feather-icon icon="Trash2Icon" class="mr-50"/>
                            <span class="align-middle">Remove</span>
                        </b-button>
                    </b-col>
                    <b-col lg="8">

                        <basic-table style="margin-top: 0 !important;" :columns="columns" :data="distributionVehicleStock" v-slot="props">

                            <span class="d-flex justify-content-center">
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </basic-table>
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>

        <b-card>
            <b-row class="mb-1">
                <b-col><h3>Load / Unload</h3></b-col>

                <b-col class="text-right" v-if="canEditLoadPermission">

                    <delete-items-button class="ml-2" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>
                    <b-button @click="onAddNewLoad" variant="success">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add new load</span>
                    </b-button>

                </b-col>
            </b-row>
            <basic-table ref="basicTable"  style="margin-top: 0 !important;" :columns="stockChangeColumns" :data="loads" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                <div v-if="props.column.displayType === 6 && canEditLoadPermission" class="d-flex justify-content-center">
                    <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                </div>
                <div v-if="canEditLoadPermission && props.column.displayType === 1" class="d-flex justify-content-center">
                    <b-button variant="warning" @click="onEditStockButtonClicked(props.row)" class="mr-1">
                        <feather-icon icon="EditIcon"/>
                    </b-button>
                    <b-button variant="danger" @click="onDeleteStockButtonClicked(props.row)" class="mr-1">
                        <feather-icon icon="TrashIcon"/>
                    </b-button>
                </div>
                <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <table>
                        <tr v-for="(product, index) in props.row.items" :key="index" style="vertical-align: middle">
                            <td class="pb-50 pt-50">{{product.product}}</td>
                            <td class="pb-50 pt-50">{{ Math.abs(product.quantity) }}x</td>
                        </tr>
                    </table>
                </div>
                <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    {{ moment(props.row.timestamp).format("YYYY/MM/DD HH:mm")  }}
                </div>
              
                <div v-else-if="props.column.displayType === 4" class="d-flex justify-content-center">
                    {{ props.row.user_name }} {{ props.row.user_last_name }}
                </div>
                <div v-else-if="props.column.displayType === 5" class="d-flex justify-content-center">
                    <b-badge v-if="props.row.loading" variant="light-success">
                        Loading
                    </b-badge>
                    <b-badge v-else variant="light-warning">
                        Unloading
                    </b-badge>
                </div>
                <span v-else class="d-flex justify-content-center">
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <b-modal title="Edit stock" v-model="editStockModalOpen" no-close-on-backdrop>
            <template #default v-if="stockBeingEdited">

                <b-form-group>
                    <label>Date</label>
                    <b-form-input readonly :value="moment(stockBeingEdited.timestamp).format(`YYYY/MM/DD HH:mm`)"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label>Warehouse</label>
                    <b-form-input readonly :value="stockBeingEdited.warehouse"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label>User</label>
                    <b-form-input readonly :value="`${stockBeingEdited.user_name} ${stockBeingEdited.user_last_name}`"></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label>Products</label>

                    <b-row v-for="item in stockBeingEdited.items" :key="item.id" class="mt-1">
             
                        <b-col class=""><h5>{{item.product}}</h5></b-col>
                        <b-col class="float-right">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-button variant="outline-primary" @click="item.quantity += -1">
                                        <feather-icon icon="MinusIcon" />
                                    </b-button>
                                </b-input-group-prepend>
                                <b-form-input placeholder="0" class="text-center" type="number" v-model="item.quantity" />
                                <b-input-group-append>
                                    <b-button variant="outline-primary" @click="item.quantity += 1">
                                        <feather-icon icon="PlusIcon" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
               
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <label>Products</label>
                    <b-row v-for="(warehouseProduct, index) in products" :key="index" class="mt-1 mx-1">
                        <b-col>

                            <b-row>
                                <b-col class=""><h5>{{warehouseProduct.name}}</h5></b-col>
                            </b-row>
                            <b-row v-for="item in warehouseProduct.products" :key="item.product_id" class="mt-1">
                             
                                <b-col>{{ item.name }}</b-col>
                                <b-col class="text-right">
                                    <b-button :disabled="isItemAlreadyInStock(item.product_id)" size="sm" @click="onAddItemFromWarehouseProductToStockByHand(item)" variant="warning">Add</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="onCloseEditStock()">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="onEditStock()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Edit load</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Load / Unload" v-model="addNewLoadModalOpen" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Warehouse</label>
                    <v-select :options="warehouses" label="name"
                              @input="onWarehouseSelected"
                    >
                    </v-select>
                </b-form-group>
                <b-form-group>
                    <label>Load / Unload</label>
                    <div class="d-flex justify-content-center align-items-center">
                        <span class="mr-2">Unload</span>
                        <span class="">
                            <b-form-checkbox
                                class="m-0"
                                switch
                                inline
                                v-model="newLoad.loading"
                            >
                            </b-form-checkbox>
                        </span>
                        <span class="ml-2">Load</span>
                        
                    </div>
                </b-form-group>
                <b-form-group>
                    <label>Products</label>
            
                    <b-row v-for="(warehouseProduct, index) in newLoad.items" :key="index" class="mt-1 mx-1">
                        <b-row>
                            <b-col class=""><h5>{{warehouseProduct.name}}</h5></b-col>
                            <b-col class="float-right">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button variant="outline-primary" @click="warehouseProduct.quantity += -1">
                                            <feather-icon icon="MinusIcon" />
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-input placeholder="0" class="text-center" type="number" v-model="warehouseProduct.quantity" />
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="warehouseProduct.quantity += 1">
                                            <feather-icon icon="PlusIcon" />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-col>
                            <ul>
                                <li v-for="product of warehouseProduct.products" :key="product.id"><b>{{ product.name }}</b> {{ warehouseProduct.quantity * product.quantity }}x</li>
                            </ul>
                        </b-col>
                    </b-row>
                </b-form-group>


            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="resetNewLoad">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="onLoadVehicle">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Load</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow, BBadge, BModal, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BFormCheckbox} from 'bootstrap-vue'
    import BasicOverview from '@/views/components/BasicOverview'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'
    export default {
        components: {
            DeleteItemsButton,
            BasicOverview,
            BasicTable,
            vSelect,
            BCard,
            BOverlay,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BBadge,
            BModal,
            BInputGroupPrepend, 
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                distributionVehicle: {
                    name: '',
                    type: '',
                    registration: '',
                    assigned_warehouse_names: ''
                },
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                warehouses: [],
                loads: [],
                products: [],
                overviewDefinitions: [
                    { key: 'name', name: 'Name', type: 0 },
                    { key: 'type', name: 'Type', type: 0 },
                    { key: 'registration', name: 'Registration', type: 0 },
                    { key: 'assigned_warehouse_names', name: 'Warehouses', type: 0, formatFn: (data) => data.join(', ') }
                ],
                editEnabled: false,
                editObject: {},
                distributionVehicleStock: [],
                columns: [
                    {
                        label: 'Product',
                        displayType: 0,
                        field: 'product',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
            
                    {
                        label: 'Quantity',
                        displayType: 0,
                        field: 'quantity',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    }
                ],
                stockChangeColumns: [
                    {
                        label: 'Select',
                        displayType: 6,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Date',
                        displayType: 3,
                        field: 'timestamp',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Warehouse',
                        displayType: 0,
                        field: 'warehouse',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'User',
                        displayType: 4,
                        field: 'user_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Loading',
                        displayType: 5,
                        field: 'loading',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Items',
                        displayType: 2,
                        field: 'items',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],
                stockBeingEdited: null,
                editStockModalOpen: false,

                addNewLoadModalOpen: false,
                requiredSelectedWarehouseFlag: null,
                newLoad: {
                    items: [],
                    warehouse_id: null,
                    loading: true
                },
                canEditLoadPermission: false

            }
        },
        methods: {
            isItemAlreadyInStock(item_id) {
                let isItemPresent = false
                for (const item of this.stockBeingEdited.items) {
                    if (item.product_id === item_id) {
                        isItemPresent = true
                        break
                    }
                }

                return isItemPresent
                
            },
            onAddItemFromWarehouseProductToStockByHand(item) {
                this.stockBeingEdited.items.push({
                    product_id: item.product_id,
                    product: item.name,
                    quantity: 1
                })
            },
            onDeleteStockButtonClicked(stockItem) {
                const thisIns = this
                this.$http.delete(`/api/management/v1/distribution_vehicle/${this.$route.params.id}/stockChange/${stockItem.id}`)
                    .then(function() {
                        thisIns.$printSuccess('Stock deleted')
                    }).catch(function(error) {
                        thisIns.$printError((error.response) ? error.response.data : error)
                    }).finally(function() {
                        thisIns.loadData()
                    })
            },
            onEditStock() {
                const thisIns = this
           
                if (!this.stockBeingEdited || !this.stockBeingEdited.items.length) return
                
                this.stockBeingEdited.items = this.stockBeingEdited.items.filter(i => i.quantity > 0)

                this.$http.put(`/api/management/v1/distribution_vehicle/${this.$route.params.id}/stockChange/${this.stockBeingEdited.id}`, this.stockBeingEdited)
                    .then(function() {
                        thisIns.$printSuccess('Stock updated')
                        thisIns.onCloseEditStock()
                    }).catch(function(error) {
                        thisIns.$printError((error.response) ? error.response.data : error)
                    }).finally(function() {
                        thisIns.loadData()
                    })
            },
            onCloseEditStock() {
                this.stockBeingEdited = null
                this.editStockModalOpen = false
            },
            canEditLoads() {
                // user either has edit all loads permission
                // or has permission to edit its own distribution vehicle
                return this.$hasPermission(this.$permissions.EditAllDistributionVehicleLoads) || 
                    (this.$hasPermission(this.$permissions.EditDistributionVehicleLoads) && this.$store.state.app.permissions.assigned_vehicle === this.$route.params.id)
            },
            onLoadVehicle() {
                if (!this.newLoad.warehouse_id) {
                    this.$printWarning('You must select warehouse')
                    return
                }

                const items = []
                for (const item of this.newLoad.items) {
                    if (!item || !item.id || !item.quantity) continue
                    const vehicleStockChangeItem = {
                        product_id: item.id,
                        quantity: item.quantity
                    }
                    items.push(vehicleStockChangeItem)
                }

                if (!items || !items.length) {
                    this.$printWarning('You must select at least one product to add to load')
                    return
                }

                this.dataLoaded = false
                const payload = {
                    warehouse_id: this.newLoad.warehouse_id,
                    distribution_vehicle_id: this.$route.params.id,
                    items
                }

                const loadTypeURL = (this.newLoad.loading ? 'load' : 'unload')

                const thisIns = this
                this.$http.post(`/api/management/v1/distribution_vehicle/${this.$route.params.id}/${loadTypeURL}`, payload)
                    .then(() => {
                        thisIns.resetNewLoad()
                        thisIns.$printSuccess(`${this.newLoad.loading ? 'Load' : 'Unload'} was successfully added`)
                        thisIns.loadData()

                    }).catch(function(error) {
                        thisIns.$printError((error.response) ? error.response.data : error)
                    }).finally(() => {
                        this.dataLoaded = true
                    })
            },
            setProductsForNewLoad() {
                this.newLoad.items = []
                this.newLoad.items = JSON.parse(JSON.stringify(this.products))

                // setting quantity as reactive property
                for (const product of this.newLoad.items) {
                    this.$set(product, 'quantity', 0)
                }
            },
            resetNewLoad() {
                this.newLoad.warehouse_id = null
                this.newLoad.loading = true
                this.setProductsForNewLoad()

                this.addNewLoadModalOpen = false
            },
            onWarehouseSelected(warehouse) {
                if (!warehouse || !warehouse.id) return
                this.newLoad.warehouse_id = warehouse.id
            },
            onAddNewLoad() {
                this.addNewLoadModalOpen = true
            },
            onEditStockButtonClicked(stock) {
                this.stockBeingEdited = JSON.parse(JSON.stringify(stock))
                this.editStockModalOpen = true
            },
            openEdit() {
                this.editObject = JSON.parse(JSON.stringify(this.distributionVehicle))
                this.editEnabled = true
            },
            closeEdit() {
                this.editEnabled = false
            },
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/distribution_vehicle/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.distributionVehicle = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const warehousesLoadPromise = this.$http.get('/api/management/v1/warehouse')
                warehousesLoadPromise.then(function(response) {
                    thisIns.warehouses = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const distributionVehicleStockLoadPromise = this.$http.get(`/api/management/v1/distribution_vehicle/${  this.$route.params.id}/stock`)
                distributionVehicleStockLoadPromise.then(function(response) {
                    thisIns.distributionVehicleStock = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const distributionVehicleStockChangeLoadPromise = this.$http.get(`/api/management/v1/distribution_vehicle/${this.$route.params.id}/stockChange`)
                    .then(function(response) {
                        thisIns.loads = response.data

                        // all quantities are always displayed positive
                        for (const load of thisIns.loads) {
                            for (const item of load.items) {
                                item.quantity = Math.abs(item.quantity)
                            }
                        }

                    }).catch(function(error) {
                        thisIns.$printError((error.response) ? error.response.data : error)
                    })
            
                const productsLoadPromise = this.$http.get('/api/management/v1/warehouse_product/')
                productsLoadPromise.then(function(response) {
                    thisIns.products = response.data
                    thisIns.setProductsForNewLoad()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, warehousesLoadPromise, distributionVehicleStockLoadPromise, distributionVehicleStockChangeLoadPromise, productsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveDistributionVehicle() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/distribution_vehicle/${  this.$route.params.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Distribution vehicle saved')
                    thisIns.editDistributionVehicleModalActive = false
                    thisIns.editEnabled = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removeDistributionVehicle() {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this distribution vehicle?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/distribution_vehicle/${  this.$route.params.id}`)
                    this.$printSuccess('Distribution vehicle removed')
                    this.$router.push({name: 'distribution_vehicles'})
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } 
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
            
        },
        watch: {
            'newLoad.items': {
                // deep watching array products
                // if quantity is negative, set it to 0
                handler() {
                    for (const product of this.newLoad.items) {

                        product.quantity = Number(product.quantity) ?? 0

                        if (product && product.quantity && product.quantity < 0) {
                            product.quantity = 0
                        }
                    }
                },
                deep: true
            },
            'stockBeingEdited.items': {
                handler() {
                    if (!this.stockBeingEdited || !this.stockBeingEdited.id) return

                    for (const product of this.stockBeingEdited.items) {
                        if (!product) continue

                        product.quantity = Number(product.quantity) ?? 0

                        if (product.quantity < 0) product.quantity = 0
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.canEditLoadPermission = this.canEditLoads()
            this.selection.collection_name = 'vehicle_stock_change'

            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>